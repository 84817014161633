import request from '@/until/request.js'
const prefixName = '/gm/webCus'
const otherName = '/gm/webCusType'
// 获取客户列表
export function GetCustomerList(data){
    return request({
      url: prefixName,
      method: 'get',
      params: { ...data }
    })
  }
// 获取预售价格模板
export function getYnyPriceTemplate(data){
    return request({
      url: '/gm/global/getTemplate',
      method: 'get',
      params: { ...data }
    })
  }
// 获取客户详情
export function GetCustomerDetails(data){
    return request({
      url: prefixName + '/details',
      method: 'get',
      params: { ...data }
    })
  }
// 获取账户编号
export function getCode(data){
    return request({
      url: prefixName + '/getCode',
      method: 'get',
      params: data
    })
  }
  // 创建客户
  export function AddCustomer(result){
    return request({
      url: prefixName,
      method: 'post',
      data: result
    })
  }
  // 修改客户
  export function EditCustomer(result){
      return request({
          url: prefixName,
          method: 'PUT',
          data: result
      })
  }
  // 删除客户
  export function DeleteCustomer(id){
      return request({
          url: prefixName + '/' + id,
          method: 'delete'
      })
  }
  // 客户期初设置
export function SettingCustomerInitial(result){
    return request({
      url: prefixName + '/initial',
      method: 'post',
      data: result
    })
  }
// 获取客户类别
export function GetCustomerTypeList(data){
    return request({
      url: otherName,
      method: 'get',
      params: { ...data }
    })
  }
  // 创建客户类别
  export function AddCustomerType(result){
    return request({
      url: otherName,
      method: 'post',
      data: result
    })
  }
  // 修改客户类别
  export function EditCustomerType(result){
      return request({
          url: otherName,
          method: 'PUT',
          data: result
      })
  }
  // 删除客户类别
  export function DeleteCustomerType(id){
      return request({
          url: otherName + '/' + id,
          method: 'delete'
      })
  }