<template>
  <div class="view-wrap">
    <div class="search">
      <el-form :inline="true">
        <el-form-item>
          <el-input
            v-model="searcForm.name"
            placeholder="请输入类别名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getData"
            ><i class="el-icon-search" /> 查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="button">
      <el-button type="primary" round @click="UpDate">
        <i class="el-icon-plus" /> 新增</el-button
      >
    </div>
    <div class="view">
      <SimpleTable
        :data="tableData"
        :tableFields="tableItem"
        :operation="operationEvent"
        :currentPage="searcForm.pageNum"
        :pageSize="searcForm.pageSize"
        :total="total"
        @sizeChange="changePageSize"
        @currentChange="changeCurrentPage"
      >
        <div slot="operation" slot-scope="row">
          <el-tooltip
            class="item"
            effect="dark"
            content="修改"
            placement="top-start"
          >
           <el-button type="text" @click="EditData(row.data)"
            ><i class="el-icon-edit"></i
          ></el-button>
          </el-tooltip>
          <!--  -->
          <el-tooltip
            class="item"
            effect="dark"
            content="删除"
            placement="top-start"
          >
           <el-button type="text" @click="DeleteData(row.data)"
            ><i class="el-icon-delete"></i
          ></el-button>
          </el-tooltip>
        </div>
      </SimpleTable>
    </div>
    <miaDialog ref="AddOrEditForm" v-if="Visible">
      <div slot="content">
        <el-form
          ref="form"
          style="width: 70%; margin: 0 auto"
          :model="submitForm"
          :rules="submitRules"
          label-width="80px"
        >
          <el-form-item label="类别名称" prop="name" key="name">
            <el-input
              v-model="submitForm.name"
              placeholder="请输入类别名称"
            ></el-input>
          </el-form-item>
          <div style="width: 100%; text-align: center; margin-top: 50px">
            <el-button type="primary" @click="submitData">提交</el-button>
            <el-button @click="close">取消</el-button>
          </div>
        </el-form>
      </div>
    </miaDialog>
  </div>
</template>
<script>
import {
  GetCustomerTypeList,
  AddCustomerType,
  EditCustomerType,
  DeleteCustomerType,
} from "@/api/customer";
export default {
  data() {
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        name: "",
      },
      total: 0,
      tableData: [],
      tableItem: [
        { prop: "name", label: "类别名称", type: "default" },
        { label: "操作", type: "operation" },
      ],
      operationEvent: [
        { type: "edit", event: this.EditData },
        { type: "delete", event: this.DeleteData },
      ],
      UpdateType: null,
      Visible: false,
      submitForm: {
        name: "",
      },
      submitRules: {
        name: [
          { required: true, message: "类别名称不能为空", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      GetCustomerTypeList(this.searcForm).then((res) => {
        const { records, current, size, total } = res.data;
        if (res.code === 1) {
          this.tableData = records;
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    UpDate() {
      this.UpdateType = 1;
      // 新增
      this.Visible = true;
      this.submitForm = {
        name: "",
      };
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("新建类别名称");
      });
    },
    submitData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.UpdateType) {
            AddCustomerType(this.submitForm).then((res) => {
              const { code, msg } = res;
              if (code === 1) {
                this.$message.success(msg);
                this.close();
                this.getData();
              } else {
                this.$message.error(msg);
              }
            });
          } else {
            EditCustomerType(this.submitForm).then((res) => {
              const { code, msg } = res;
              if (code === 1) {
                this.$message.success(msg);
                this.close();
                this.getData();
              } else {
                this.$message.error(msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.$refs.AddOrEditForm.close();
    },
    EditData(data) {
      this.UpdateType = 0;
      this.submitForm.id = data.id;
      this.submitForm.name = data.name;
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("修改类别名称");
      });
    },
    DeleteData(data) {
      this.$confirm(`是否删除该类别？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        DeleteCustomerType(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
  },
};
</script>